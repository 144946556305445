import React, { Component } from "react";
import { autorun } from "mobx";
import { observer } from "mobx-react";

import { dateFormat } from "@util";
import { TableDataSource, WrappedValue } from "components/FullTable";
import ChooserModal from "components/ChooserModal";

const rowKey = item => `${item.CampKit.codigo_kit}:${item.CampKit.operacao_frete}`;

@observer
export default class ChooserKit extends Component {
  constructor(props) {
    super(props);

    this.ds = new TableDataSource({
      uri: "/pedidos/kits/000000-00-00/F/C/000000/000000",
      fetched: true
    });

    this.state = {
      visible: false,
      onChoose: null,
      codigoComboKit: null,
    };
  }
  componentDidMount() {
    this._watchDS = autorun(
      () => {
        const { data } = this.props.pedido;
        const codigo_cliente = `${data.codigo_cliente}`.padStart(6, "0");
        const loja_cliente = !!data.loja_cliente ? `${data.loja_cliente}`.padStart(2, "0") : "01";
        const filial_faturamento = !!data.filial_faturamento ? `${data.filial_faturamento}`.padStart(2, "0") : "01";
        const tipo_transp = !!this.props.pedido.tipoFreteTabela ? this.props.pedido.tipoFreteTabela : "C";
        const op_logistica = !!data.op_logistica ? data.op_logistica : "F";
        const cliente_retira = !!data.tipo_frete ? (data.tipo_frete === "C" ? "NAO" : "SIM") : "NAO";
        const perfil_auto = !!this.props.pedido?.policy?.data?.cod_perfil_auto ? this.props.pedido?.policy?.data?.cod_perfil_auto : this.props.pedido?.policy?.data.cod_perfil_auto;
        const perfil_imob = !!this.props.pedido?.policy?.data?.cod_perfil_imob ? this.props.pedido?.policy?.data?.cod_perfil_imob : this.props.pedido?.policy?.data.cod_perfil_imob;
        this.ds.uri = "/pedidos/kits/" + [codigo_cliente, loja_cliente, filial_faturamento].join("-") + "/" + op_logistica + "/" + cliente_retira + "/" + perfil_imob + "/" + perfil_auto + "/" + tipo_transp;

        // const isCodigoComboKit = !!this.state.codigoComboKit;

        // console.log('this.state.codigoComboKit -> ', this.state.codigoComboKit);
        // console.log('isCodigoComboKit -> ', isCodigoComboKit);

        // if (isCodigoComboKit) {
        //   this.ds.uri = "/pedidos/kitsCombo/" + [codigo_cliente, loja_cliente, filial_faturamento].join("-") + "/" + op_logistica + "/" + cliente_retira + "/" + perfil_imob + "/" + perfil_auto + "/" + tipo_transp + "/" + this.state.codigoComboKit;
        //   this.ds.defaultFilter.codigoComboKit = this.state.codigoComboKit;
        // } else {
        //   this.ds.uri = "/pedidos/kits/" + [codigo_cliente, loja_cliente, filial_faturamento].join("-") + "/" + op_logistica + "/" + cliente_retira + "/" + perfil_imob + "/" + perfil_auto + "/" + tipo_transp;
        // }

        //this.ds.uri = "/pedidos/kits/" + [codigo_cliente, loja_cliente, filial_faturamento].join("-") + "/" + op_logistica + "/" + cliente_retira + "/" + tipo_transp;
      },
      { delay: 60 }
    );
  }
  componentWillUnmount() {
    if (!!this._watchDS) this._watchDS();
  }
  onSelectRow = (...args) => {
    const { onChoose } = this.state;
    if (!!onChoose && typeof onChoose === "function") {
      onChoose(...args);
    }
    if (!this.state.codigoComboKit) {
      this.close();
    }
  };
  setUriKit = (codigoComboKit) => {
    const { data } = this.props.pedido;
    const codigo_cliente = `${data.codigo_cliente}`.padStart(6, "0");
    const loja_cliente = !!data.loja_cliente ? `${data.loja_cliente}`.padStart(2, "0") : "01";
    const filial_faturamento = !!data.filial_faturamento ? `${data.filial_faturamento}`.padStart(2, "0") : "01";
    const tipo_transp = !!this.props.pedido.tipoFreteTabela ? this.props.pedido.tipoFreteTabela : "C";
    const op_logistica = !!data.op_logistica ? data.op_logistica : "F";
    const cliente_retira = !!data.tipo_frete ? (data.tipo_frete === "C" ? "NAO" : "SIM") : "NAO";
    const perfil_auto = !!this.props.pedido?.policy?.data?.cod_perfil_auto ? this.props.pedido?.policy?.data?.cod_perfil_auto : this.props.pedido?.policy?.data.cod_perfil_auto;
    const perfil_imob = !!this.props.pedido?.policy?.data?.cod_perfil_imob ? this.props.pedido?.policy?.data?.cod_perfil_imob : this.props.pedido?.policy?.data.cod_perfil_imob;

    if (codigoComboKit) {
      this.ds.uri = "/pedidos/kitsCombo/" + [codigo_cliente, loja_cliente, filial_faturamento].join("-") + "/" + op_logistica + "/" + cliente_retira + "/" + perfil_imob + "/" + perfil_auto + "/" + tipo_transp + "/" + codigoComboKit;
    } else {
      this.ds.uri = "/pedidos/kits/" + [codigo_cliente, loja_cliente, filial_faturamento].join("-") + "/" + op_logistica + "/" + cliente_retira + "/" + perfil_imob + "/" + perfil_auto + "/" + tipo_transp;
    }
  }
  open = (onChoose = null, codigoComboKit = null) => {
    console.log('codigoComboKit -> ', codigoComboKit);
    this.setUriKit(codigoComboKit);
    this.setState({ visible: true, onChoose, codigoComboKit });
  };
  close = () => {
    this.setState({ visible: false, codigoComboKit: null });
  };
  render() {
    const columns = [
      {
        title: "Descrição",
        key: "titulo_kit",
        dataIndex: "CampKit.titulo_kit",
        className: "text-left",
      },
      {
        title: "Data",
        key: "dt_kit_inicial",
        dataIndex: "CampKit.dt_kit_inicial",
        render: (text, item) => (
          <WrappedValue
            value={`${dateFormat(item.CampKit.dt_kit_inicial)} até ${dateFormat(item.CampKit.dt_kit_final)}`.replace(
              /\/20/gi,
              "/"
            )}
            type="text"
          />
        ),
        width: "15.2%",
      },
      {
        title: "Programação",
        key: "dt_prog_inicial",
        dataIndex: "CampKit.dt_prog_inicial",
        render: (text, item) => (
          <WrappedValue
            value={`${dateFormat(item.CampKit.dt_prog_inicial)} até ${dateFormat(item.CampKit.dt_prog_final)}`.replace(
              /\/20/gi,
              "/"
            )}
            type="text"
          />
        ),
        width: "15.2%",
      },
      {
        title: "Adicional",
        key: "adicional_qtd_minima",
        dataIndex: "CampKit.adicional_qtd_minima",
        render: (text, item) => <WrappedValue value={`${text}%`} type="text" />,
        width: "7.5%",
      },
      {
        title: "Tipo Qtd.",
        key: "tipo_qtd_total",
        dataIndex: "CampKit.tipo_qtd_total",
        width: "7.9%",
      },
      {
        title: "Qtd min",
        key: "qtd_inicial",
        dataIndex: "CampKit.qtd_inicial",
        width: "7%",
      },
      {
        title: "Qtd max",
        key: "qtd_final",
        dataIndex: "CampKit.qtd_final",
        width: "7%",
      },
      {
        title: "Proporção",
        key: "proporcao",
        dataIndex: "CampKit.proporcao",
        width: "8%",
      },
      {
        title: "Min. prop.",
        key: "qtd_min_proporcao",
        dataIndex: "CampKit.qtd_min_proporcao",
        width: "8%",
      },
    ];

    return (
      <ChooserModal
        visible={this.state.visible}
        rowKey={rowKey}
        columns={columns}
        dataSource={this.ds}
        title="Selecione um Kit de Campanha"
        errorMessage="Não foi possível obter seus kits no momento"
        searchPlaceholder="Busque pela descrição do kit"
        onSelectRow={this.onSelectRow}
        onCancel={this.close}
        onOk={this.close}
        width={990}
        {...this.props}
      />
    );
  }
}
