import React, { PureComponent } from "react";
import { Link, withRouter } from "react-router-dom";
import { Menu, Icon } from "antd";
import classnames from "classnames";
import styled from "styled-components";
import { MenuWrapper, StyledMenu } from "components/Menu";
import ExternalLink from "components/ExternalLink";
import { isDev } from "@env";

const SubMenu = Menu.SubMenu;
const MenuItem = Menu.Item;

const VENDEDOR_RELATORIOS_LINK = [
  {
    key: "volume_perfil",
    href: "/r/volume_perfil",
    label: "Volume perfil ",
    title: "Volume perfil ",
    plataforma: "NEXXUS PRO",
  },
  { key: "nps", href: "/r/nps", label: "NPS - Respostas Clientes", title: "NPS - FeedBack Clientes" },
  { key: "maquinas", href: "/r/maquinas", label: "Máquinas", title: "Máquinas" },
  {
    key: "analise-volume",
    href: "/r/analise-volume",
    label: "Análise de Volume de Maquinas",
    title: "Análise de Volume de Maquinas",
    isB2B: false,
  },
  { key: "nf", href: "/notas-fiscais", label: "Notas Fiscais - DANFE", title: "NF-e / DANFE / XML" },
  { key: "pedidos", href: "/r/pedidos", label: "Pedidos por Período" },
  { key: "faturados", href: "/r/faturados", label: "Produtos Faturados" },
  { key: "nao_faturados", href: "/r/nao_faturados", label: "Produtos Não Faturados" },
  { key: "faturamento", href: "/r/faturamento", label: "Faturamento Acumulado" },
  {
    key: "resumo_cashback",
    href: "/r/resumo_cashback",
    label: "Cashback ",
    title: "Cashback ",
    plataforma: "NEXXUS PRO",
  },
  { key: "saldo", href: "/r/saldo", label: "Saldo em Estoque" },
  { key: "transportadoras", href: "/r/transportadoras", label: "Transportadoras" },
  { key: "condicoes", href: "/r/condicoes", label: "Condições de Pagto", title: "Condições de Pagamento" },
  { key: "ranking", href: "/r/ranking", label: "Ranking Volume", title: "Ranking Volume", plataforma: "NEXXUS PRO" },
  { key: "alvaras", href: "/r/alvaras", label: "Informações Alvará" },
  { key: "leadtime_log", href: "/r/leadtime", label: "Lead Time Logístico", title: "Lead Time Logístico" },
  { key: "titulos_abertos", href: "/r/titulos_abertos", label: "Títulos em Aberto" },
  { key: "boletos", href: "/boletos", label: "Boletos em Aberto" },
  { key: "clientes", href: "/r/clientes", label: "Relação de Clientes" },
  { key: "planejamento", href: "/r/planejamento", label: "Planejamento" },
  { key: "retencao-cliente", href: "/r/retencao-cliente", label: "Retenção Cliente", plataforma: "NEXXUS PRO" },
];

const TagNexxusPro = styled.div`
  display: inline-block;
  background-color: #195488;
  color: #fff;
  float: right;
  font-weight: 600;
  font-size: 9px;
  line-height: 11px;
  padding: 6px 7px;
  border-radius: 4px;
  margin-right: 2px;
  margin-top: 12px;
  cursor: default;
`;

class MenuVendedor extends PureComponent {
  render() {
    const {
      isB2B,
      isTipoVendedor,
      isTipoVendaInterna,
      isNexxusPro,
      collapsed = false,
      selected = [],
      resetTabs,
      ...props
    } = this.props;
    const submenuKeys = [];

    return (
      <MenuWrapper className={classnames("sidebar-nav", "sidebar-nav__vendedor")} {...props}>
        <StyledMenu
          mode="inline"
          selectable={false}
          defaultSelectedKeys={selected}
          defaultOpenKeys={!collapsed ? submenuKeys : []}
          inlineCollapsed={collapsed}
          inlineIndent={16}>
          <MenuItem key="nav-home">
            <Link to="/">
              <Icon type="home" />
              <span>Home</span>
            </Link>
          </MenuItem>
          <SubMenu
            key="subRepr"
            title={
              <span>
                <Icon type="star-o" />
                <span>Representante Comercial</span>
              </span>
            }>
            <MenuItem key="nav-repr-pedidos">
              <Link to="/pedidos" onClick={event => resetTabs()}>
                Pedidos de Venda
              </Link>
            </MenuItem>
            {!isB2B && (
              <MenuItem key="nav-repr-sd">
                <Link to="/solicitacao-desconto" title="Solicitação Conta corrente">
                  Solicitação Conta corrente
                </Link>
              </MenuItem>
            )}
            {(isTipoVendedor || isTipoVendaInterna) && (
              <MenuItem key="nav-repr-memorandos">
                <Link to="/memorandos">Memorando Interno</Link>
              </MenuItem>
            )}
            {isNexxusPro && (
              <MenuItem key="nav-repr-chamados" className="ant-menu-item__featured">
                <Link to="/chamados">
                  <Icon type="unlock" />
                  <span>Chamados</span>
                  <TagNexxusPro>NEXXUSPRO</TagNexxusPro>
                </Link>
              </MenuItem>
            )}
            {isNexxusPro && isDev && (
              <MenuItem key="nav-sac" className="ant-menu-item__featured">
                <Link to="/sac">
                  <Icon type="unlock" />
                  <span>SAC</span>
                  <TagNexxusPro>NEXXUSPRO</TagNexxusPro>
                </Link>
              </MenuItem>
            )}
            <MenuItem key="nav-repr-sda">
              <Link to="/sda">SDA Monitor</Link>
            </MenuItem>
            {!isB2B &&
              <MenuItem key="nav-repr-maquinas">
                <Link to="/maquinas-tintometricas" onClick={() => resetTabs()}>
                  Solicitações Máquinas Tintométricas
                </Link>
              </MenuItem>
            }
            {!isB2B &&
              <MenuItem key="nav-repr-maquinas">
                <Link to="/maquinas-tintometricas/devolucoes" onClick={() => resetTabs()}>
                  Devoluções Máquinas Tintométricas
                </Link>
              </MenuItem>
            }
          </SubMenu>
          <SubMenu
            key="subVendasDistr"
            title={
              <span>
                <Icon type="bars" />
                <span>Vendas e Distribuição</span>
              </span>
            }>
            <MenuItem key="nav-vendas-distr-arq">
              <Link to="/arquivos-comercial">Arquivos Comerciais</Link>
            </MenuItem>
            <MenuItem key="nav-vendas-distr-cadastro">
              <Link to="/vendedor/clientes">Manutenção Cliente</Link>
            </MenuItem>
            {!isB2B && (
              <MenuItem key="nav-cadastro-promotoria">
                <Link to="/promotoria/index">
                  <span>Promotoria</span>
                </Link>
              </MenuItem>
            )}
            {!isB2B && isNexxusPro && (
              <MenuItem key="nav-clubea" className="ant-menu-item__featured">
                <Link to="/clubea">
                  <Icon type="unlock" />
                  <span>Clube A</span>
                  <TagNexxusPro>NEXXUSPRO</TagNexxusPro>
                </Link>
              </MenuItem>
            )}
            {!isB2B && isNexxusPro && (
              <MenuItem key="nav-vend-ano" className="ant-menu-item__featured">
                <Link to="/vendedorano">
                  <Icon type="unlock" />
                  <span>Vendedor do Ano</span>
                  <TagNexxusPro>NEXXUSPRO</TagNexxusPro>
                </Link>
              </MenuItem>
            )}
          </SubMenu>
          <SubMenu
            key="subTabelaPreco"
            title={
              <span>
                <Icon type="tags" />
                <span>Tabela de Preço Unificada</span>
              </span>
            }>
            {!isNexxusPro && (
              <MenuItem key="nav-tabela-preco">
                <Link to="/tabela-preco">Tabela de Preço</Link>
              </MenuItem>
            )}
            {isNexxusPro && (
              <MenuItem key="nav-tabela-preco" className="ant-menu-item__featured">
                <Link to="/tabela-preco-sku">
                  <Icon type="unlock" />
                  <span>Tabela de Preço </span>
                  <TagNexxusPro>NEXXUSPRO</TagNexxusPro>
                </Link>
              </MenuItem>
            )}
          </SubMenu>
          <SubMenu
            key="subRelatorios"
            title={
              <span>
                <Icon type="appstore-o" />
                <span>Relatórios</span>
              </span>
            }>
            {VENDEDOR_RELATORIOS_LINK.filter(
              item =>
                !item.disabled &&
                ((item.plataforma === "NEXXUS PRO" && isNexxusPro) || !item.plataforma) &&
                (isB2B === item.isB2B || item.isB2B === undefined)
            ).map(item => (
              <MenuItem
                key={`nav-relatorios-${item.key}`}
                className={item.plataforma === "NEXXUS PRO" ? "ant-menu-item__featured" : ""}>
                <Link to={item.href} title={item.title || item.label}>
                  {item.plataforma === "NEXXUS PRO" && <Icon type="unlock" />}
                  {item.label}
                  {item.plataforma === "NEXXUS PRO" && <TagNexxusPro>NEXXUSPRO</TagNexxusPro>}
                </Link>
              </MenuItem>
            ))}
          </SubMenu>
          <MenuItem key="nav-cadastro">
            <Link to="/vendedor/cadastro">
              <Icon type="user" />
              <span>Meu Cadastro</span>
            </Link>
          </MenuItem>
          <SubMenu
            key="subLinks"
            title={
              <span>
                <Icon type="link" />
                <span>Links Úteis</span>
              </span>
            }>
            <MenuItem key="nav-links-produtos">
              <ExternalLink to="https://drive.google.com/drive/folders/0B_YCv-6xkOdvTHZTaWE5TGFYUEU?resourcekey=0-oL1MxFa_2UqT3YEk7DO9jw">
                Catálogo de Produtos
              </ExternalLink>
            </MenuItem>
            <MenuItem key="nav-links-telefones">
              <ExternalLink to="https://drive.google.com/drive/folders/0B_YCv-6xkOdvaWdvRDloc1FMUlk?resourcekey=0-FkGzi6rE9fFRO_iPDcR03A">
                Catálogo de Cor
              </ExternalLink>
            </MenuItem>
            <MenuItem key="nav-links-arsenal">
              <ExternalLink to="https://arsenal.anjo.com.br">Arsenal ANJO</ExternalLink>
            </MenuItem>
            <MenuItem key="nav-links-telefones">
              <ExternalLink to="https://www.anjo.com.br/fale-conosco/telefones">Telefones Úteis</ExternalLink>
            </MenuItem>
            <MenuItem key="nav-links-telefones">
              <ExternalLink to="https://anjotintas.zendesk.com/hc/pt-br/requests/new">
                Abertura SAC - ZENDESK
              </ExternalLink>
            </MenuItem>
          </SubMenu>
        </StyledMenu>
      </MenuWrapper>
    );
  }
}

export default withRouter(MenuVendedor);
