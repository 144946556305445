import React, { PureComponent, Fragment } from "react";
import { Spin } from "antd";
import { CartesianGrid, ComposedChart, Bar, XAxis, YAxis, Legend, Label, Line, ReferenceLine } from "recharts";
import styled from "styled-components";

import { n } from "@util";
import { fetchSemaforoTV } from "actions/dashboard";
import "./StyledPanel.css";
import moment from "moment";

const DashboardBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

console.log('window.innerHeight -> ', window.innerHeight);

const CustomizedLabel = ({ x = 0, y = 0, value, unit = "", isTicketMedio }) => {
  const angle = isTicketMedio ? -45 : 0;

  return (
    <text
      x={x}
      y={y - 5}
      fontSize={'2vw'}
      fontWeight={600}
      fill="#222"
      transform={`rotate(${angle}, ${x}, ${y - 20})`}>
      {unit ? unit + " " + n(value, 0) : n(value, 0)}
    </text>
  )
};

const CustomBarShape = (props) => {
  const { x, y, width, height, meta, realizado, tipo } = props;

  let color = realizado >= meta ? '#4CAF50' : '#F44336';

  if (tipo === 'venda_mix') {
    color = realizado >= 0 && realizado < 50 ? '#F44336' : (realizado >= 50 && realizado < 100 ? '#FFB84D' : '#4CAF50');
  }

  return <rect x={x} y={y} width={width} height={height} fill={color} />;
};

const CustomSVG = ({ color }) => {
  return <svg
    className="recharts-surface"
    width="14"
    height="14"
    viewBox="0 0 32 32"
    version="1.1"
    style={{ display: 'inline-flex', verticalAlign: 'middle', margin: 'auto 4px auto 4px', width: '2vw', height: '2vw' }}
  >
    <path
      stroke="none"
      fill={color}
      d="M0,4h32v24h-32z"
      className="recharts-legend-icon"
    />
  </svg>
}

const CustomLegend = ({ payload, isVendaMix }) => {
  const style = { color: '#666', fontSize: '2vw' };

  if (isVendaMix) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        {payload.map((entry, index) => (
          <div key={`item-${index}`} style={{ margin: '0 10px', textAlign: 'center', display: 'flex' }}>
            <CustomSVG color='#F44336' />
            <span style={style}>0 a 50 Crítico </span>
            <CustomSVG color='#FFB84D' />
            <span style={style}>50 a 100 Atenção </span>
            <CustomSVG color='#4CAF50' />
            <span style={style}>Acima de 100 Ideal </span>
          </div>
        ))}
      </div>
    );
  } else {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        {payload.map((entry, index) => (
          <div key={`item-${index}`} style={{ margin: '0 10px', textAlign: 'center', display: 'flex' }}>
            <CustomSVG color={entry.color} />
            <span style={style}>{entry.value}</span>
          </div>
        ))}
      </div>
    );
  }
};

class CustomizedAxisTick extends PureComponent {
  render() {
    const { x, y, payload } = this.props;

    return (
      <g transform={`translate(${x},${y})`}>
        <text x={0} y={0} dy={16} textAnchor="end" fill="#666" style={{ fontSize: '1.5vw' }}>
          {payload.value}
        </text>
      </g>
    );
  }
}

const MixChart = ({ keyLabel = "mes", unit = "", title = "", titleX = "", data = [], tipo }) => {
  return (
    <Spin spinning={!!data && data.length <= 0}>
      {!!data && data.length > 0 ?
        < ComposedChart
          width={window.innerWidth * 0.9}
          height={window.innerHeight * 0.9}
          data={data}
          margin={{
            top: 40,
            right: 55,
            left: 55,
            bottom: 20,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey={keyLabel} tick={<CustomizedAxisTick />}>
            <Label value={titleX} offset={20} angle={0} position="left" />
          </XAxis>
          <YAxis
            tick={<CustomizedAxisTick />}
            tickFormatter={tick => {
              return n(tick, 0);
            }}
          >
            <Label value={title} align="left" offset={-30} angle={0} position="insideTopLeft" />
          </YAxis>
          {/* <Tooltip formatter={
          (value, label) => label === "participacao" ? n(value * 100, 2) + ' %' : `${unit} ` + n(value, 2)
        } /> */}
          <Legend content={<CustomLegend />} isVendaMix={tipo === 'venda_mix'} />
          {/* <Bar dataKey="realizado" stackId="a" fill={"#02aed9"} label="realizado" name="realizado" /> */}
          <Bar dataKey="realizado" shape={<CustomBarShape tipo={tipo} />} label={<CustomizedLabel unit={unit} isTicketMedio={tipo === "ticket_medio"} />} />
          {tipo !== 'venda_mix' && <Line type="monotone" name="Meta" dataKey="meta" stroke="#ff7300" />}

          {tipo === 'venda_mix' && <ReferenceLine type="monotone" name="Meta" y={100} stroke="#ff7300" strokeWidth={3} strokeDasharray="5 5" dot={false} />}
          {tipo === 'venda_mix' && <ReferenceLine type="monotone" name="Meta" y={50} stroke="#ff7300" strokeWidth={3} strokeDasharray="5 5" dot={false} />}

        </ComposedChart>
        :
        <div style={{
          width: 480,
          height: 280,
          top: 40,
          right: 55,
          left: 55,
          bottom: 20,
        }} />
      }
    </Spin >
  )
};

export default class SemaforoOTPanel extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      data: null,
      isLoading: false,
      VOL: null,
      FAT: null,
      DETVOL: null,
      DETFAT: null,
    };
  }
  componentDidMount() {
    (async () => {
      await this.fetchData();
      const interval = setInterval(this.fetchData, 300000);
      this.setState({ interval });
    })()
  }

  componentWillUnmount() {
    clearInterval(this.state.interval);
    this.setState({ interval: false });
  }
  fetchData = async () => {
    try {
      this.setState({ isLoading: true });

      const response = await fetchSemaforoTV(this.props.tipo);
      if (response.success) {
        const { data } = response;
        this.setState({ data });
      }
    } catch (err) {
    } finally {
      this.setState({ isLoading: false });
    }
  };
  handleTitle() {
    const { tipo } = this.props;
    let title = '';
    const ano = moment().format('YYYY');
    switch (tipo) {
      case 'nps':
        title = 'Televendas NPS - ' + ano
        break;
      case 'ticket_medio':
        title = 'Televendas Ticket Médio (R$) - ' + ano
        break;
      case 'venda_mix':
        title = 'Televendas Venda Mix - ' + ano
        break;
      default:
        break;
    }
    return (
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: "center",
        fontSize: 19
      }}>
        <h1 style={{ marginBottom: 0 }}>{title}</h1>
      </div>
    )
  }

  handleData(data) {
    if (data && data.length <= 12) {
      const toMock = 12 - data.length;

      for (let i = 0; i < toMock; i++) {
        data.push({
          mes: moment().add(i + 1, 'months').format('MMM'),
          realizado: 0,
          meta: data[0].meta,
        });
      }
    }
    return data;
  }

  render() {
    const { tipo } = this.props;
    const { data } = this.state;

    return (
      <div style={{ minHeight: 380 }}>
        {this.handleTitle()}
        <DashboardBody>
          <Fragment>
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", width: "100%", paddingBottom: 5, }}>
              {tipo === 'nps' && <MixChart title="NPS" keyLabel="mes" unit="" titleX="Mês" data={this.handleData(data)} tipo={tipo} />}
              {tipo === 'ticket_medio' && <MixChart title="Ticket Médio" keyLabel="mes" titleX="Mês" data={this.handleData(data)} tipo={tipo} />}
              {tipo === 'venda_mix' && <MixChart title="Venda Mix" keyLabel="mes" unit="" titleX="Mês" data={this.handleData(data)} tipo={tipo} />}
            </div>
          </Fragment>
        </DashboardBody>
      </div>
    );
  }
}
