import React, { Component } from "react";
import { Row, Col, Form, Input, Checkbox, Button, Icon } from "antd";
import classnames from "classnames";
import { toJS } from "mobx";
import { observer } from "mobx-react";

import { n, maskedInputValue, dateFormat, moneyFixed } from "@util";
import InputSearch from "components/InputSearch";
import ProdutoTag, { ProdutoTagGroup } from "components/pedidos/ProdutoTag";

import "./FormProduto.less";
import { fetchApi } from "actions";

const FormItem = Form.Item;

const formItemLayout = {};

const ProdutoExtra = observer(({ item, pedido }) => {
  return (
    <ProdutoTagGroup textAlign="right">

      {!!item.isCashback && (
        <ProdutoTag color="#195488" title="CASHBACK">
          <Icon type="sync" spin={true} style={{ marginRight: 5, fontWeight: "bold" }} />
          CASHBACK
        </ProdutoTag>
      )}

      {item.hasCampanha && <ProdutoTag color={item.desc_camp === 0 ? "#AC1A22" : "#33af1a"}>{item.tagCampanha()}</ProdutoTag>}
      <ProdutoTag color="#AC1A22">Qtd. Emb: {+item.quantidade_por_fardo < 1 ? n(+item.quantidade_por_fardo, 2) : Math.max(+item.quantidade_por_fardo, 1)}</ProdutoTag>
      {!!item.ultimo_preco && (
        <ProdutoTag color={item.ultimo_preco > item.preco_vendido ? "#33af1a" : "#AC1A22"} title="Último Preço"
          icon={item.ultimo_preco > item.preco_vendido ? "up" : "down"}>
          Últ. Preço: R$ {n(item.ultimo_preco, 2)}
        </ProdutoTag>
      )}
      {!!item.data_ultimo_preco && (
        <ProdutoTag color="#AC1A22" title="Último Faturamento">
          Últ. Fat.: {dateFormat(item.data_ultimo_preco)}
        </ProdutoTag>
      )}
      {!!item.densidade > 0 && (
        <ProdutoTag color="#AC1A22" title="Conversor">
          Conversor: {n(item.densidade, 2, ",", ".")}
        </ProdutoTag>
      )}

    </ProdutoTagGroup>
  );
});

@observer
export default class FormProduto extends Component {
  constructor(props) {
    super(props);

    this.state = {
      current: {},
      focused: null,
    };
  }
  componentWillUnmount() {
    if (!!this._requestFocus) clearTimeout(this._requestFocus);
  }
  resetForm = () => {
    this.props.item.reset();
  };
  saveInput = field => _ref => {
    this._inputs[field] = _ref;
  };
  requestFocus = field => {
    if (!!this._inputs[field]) {
      this._requestFocus = setTimeout(() => {
        try {
          this._inputs[field].focus();
        } catch (ex) { }
      }, 1000 / 60);
    }
  };
  saveCurrentValue = field => event => {
    const { current } = this.state;
    const value = typeof event === "string" ? event : event.target.value;
    this.setState({ focused: field, current: { ...current, [field]: value } });
  };
  updateItemValue = (field, onChange = null, tr = v => v) => event => {
    const { item } = this.props;
    item[field] = tr(typeof event === "string" ? event : event.target.value);

    if (!!onChange && typeof onChange === "function") {
      onChange(field, toJS(item[field]));
    }

    if (field === 'conversao' && +item.densidade > 0) {
      item['quantidade'] = moneyFixed(+item[field] * +item.densidade, 2);
    }

  };

  hasChanged = field => {
    const { current } = this.state;
    const { item } = this.props;

    return !current[field] || `${current[field]}` !== `${toJS(item[field])}`;
  };
  afterChanged = async (field, onChanged, nextFocus = null) => {
    if (this.hasChanged(field) && !!onChanged && typeof onChanged === "function") {
      await onChanged(field);
    }
    if (!!nextFocus) {
      this.requestFocus(nextFocus);
    }
  };
  getInputProps = (field, customProps = {}) => {
    const { item } = this.props;
    const { onChange = null, ...extraProps } = customProps;

    return {
      ref: this.saveInput(field),
      name: field,
      value: item[field],
      onFocus: this.saveCurrentValue(field),
      onChange: this.updateItemValue(field, onChange),
      ...extraProps,
    };
  };
  checkQuantidade = () => {
    if (this.hasChanged("quantidade")) {
      const { item, onPedidoError } = this.props;
      try {
        item.checkQuantidade();
      } catch (err) {
        onPedidoError(
          err.name === "PedidoError" && !!err.message ? err.message : `Não pode ser vendido fardo quebrado`,
          () => this.requestFocus("quantidade")
        );
      }
    }
  };
  checkDesconto = () => {
    if (this.hasChanged("desc_perfil")) {
      const { item, onPedidoError } = this.props;
      try {
        item.checkDesconto();
      } catch (err) {
        item.calculaPreco();

        onPedidoError(
          err.name === "PedidoError" && !!err.message ? err.message : `Desconto não permitido para o perfil!`,
          () => this.requestFocus("desc_perfil")
        );
      }
    }
  };
  checkPreco = () => {
    if (this.hasChanged("preco_vendido")) {
      const { item, onPedidoError } = this.props;
      try {
        item.checkPreco();
      } catch (err) {
        item.calculaDesconto();

        onPedidoError(
          err.name === "PedidoError" && !!err.message ? err.message : `Valor abaixo do mínimo permitido!`,
          () => this.requestFocus("preco_vendido")
        );

        return false;
      } finally {
        this.checkDesconto();
      }
    }
    return true;
  };
  addItem = event => {
    if (this.props.onAdd()) {
      this.props.requestFocus();
    }
  };
  chooserProduto() {
    const { item, chooser, onFetch } = this.props;

    chooser("produto", async data => {
      if (!!data.Produto) {
        this.resetForm();

        item.codigo_produto = data.Produto.codigo;
        item.descricao = data.Produto.descricao;

        await onFetch(item);

        if (this.props.pedido.data.isValidKit) {
          await fetchApi(`/pedidos/get-product-ticket/${data.Produto.codigo}`).then(response => {
            item.quantidade_max_liberado_kit = response?.data?.data?.zy1_qntd;
            item.isValidTicket = +response?.data?.data?.zy1_qntd > 0 ? true : false;
          })
        }

        this.requestFocus("ped_compra");
      }
    });
  }
  chooserGrade() {
    const { item, chooser, onFetch, pedido } = this.props;

    if (pedido.data.status_pedido === "T" || (pedido.data.isValidKit && pedido.data.num_kit)) {
      chooser("grade_orcamento_tintometrico", async data => {
        if (!!data.Produto) {
          this.resetForm();

          item.codigo_produto = data.Produto.codigo;
          item.descricao = data.Produto.descricao;

          await onFetch(item);

          this.requestFocus("ped_compra");
        }
      });
      return;
    }

    chooser("grade", async data => {
      if (!!data.Produto) {
        this.resetForm();

        item.codigo_produto = data.Produto.codigo;
        item.descricao = data.Produto.descricao;

        await onFetch(item);

        this.requestFocus("ped_compra");
      }
    });
  }
  render() {
    const {
      isLoading = false,
      isB2B = false,
      item,
      pedido,
      chooser,
      onFetch,
      onAdd,
      onPedidoError,
      requestFocus,
      ...props
    } = this.props;

    const inputProps = {
      readOnly: isLoading || this.state.isLoading,
      disabled: !item.codigo_produto || !item.descricao,
      size: "small",
    };

    const valid = !isLoading && item.isValid;

    return (
      <Form noValidate layout="vertical" className="form-produto" {...props}>
        <Row gutter={12}>
          {!isB2B && (
            <Col span={1}>
              <FormItem style={{ marginTop: 19 }}>
                <Button
                  size="small"
                  type="primary"
                  icon="bars"
                  style={{
                    display: "block",
                    marginTop: -1,
                    width: "calc(100% - 4px)",
                    minWidth: 32,
                    height: 29,
                    padding: "3px 8px",
                  }}
                  onClick={value => this.chooserGrade()}
                // disabled={pedido.data.isValidKit || pedido.data.status_pedido === "T"}
                />
              </FormItem>
            </Col>
          )}
          <Col span={3}>
            <FormItem {...formItemLayout} label="Produto">
              <InputSearch
                {...this.getInputProps("codigo_produto")}
                size="small"
                readOnly={isLoading}
                onBlur={() =>
                  this.afterChanged(
                    "codigo_produto",
                    async () => {
                      await onFetch(item);
                      this.saveCurrentValue("codigo_produto")(!!item.codigo_produto ? item.codigo_produto : "");
                    },
                    "ped_compra"
                  )
                }

                onSearch={value => this.chooserProduto()}
                onPressEnter={event => {
                  if (event.target.value.trim() === "") {
                    this.chooserProduto();
                  } else {
                    this.afterChanged(
                      "codigo_produto",
                      async () => {
                        await onFetch(item);
                        this.saveCurrentValue("codigo_produto")(!!item.codigo_produto ? item.codigo_produto : "");
                      },
                      "ped_compra"
                    );
                  }
                }}
              />
            </FormItem>
          </Col>
          <Col span={5}>
            <FormItem {...formItemLayout} label="Descrição">
              <Input {...inputProps} value={item.descricao} readOnly={true} />
            </FormItem>
          </Col>
          <Col span={2}>
            <FormItem {...formItemLayout} label="P.Compra">
              <Input
                {...this.getInputProps("ped_compra", inputProps)}
                onPressEnter={event => this.requestFocus("item_pc")}
                maxLength={15}
              />
            </FormItem>
          </Col>
          <Col span={2}>
            <FormItem {...formItemLayout} label="Item PC">
              <Input
                {...this.getInputProps("item_pc", inputProps)}
                onPressEnter={event => {
                  if (!!item.canChangeQuantidade && isB2B) {
                    this.requestFocus("conversao");
                  }
                  else {
                    this.requestFocus("quantidade");
                  }
                }}
                maxLength={6}
              />
            </FormItem>
          </Col>
          {isB2B && (
            <Col span={2}>
              <FormItem {...formItemLayout} label="Conversão">
                <Input
                  {...inputProps}
                  type="number"
                  min="0"
                  step="1"
                  lang="en-150"
                  ref={this.saveInput("conversao")}
                  disabled={inputProps.disabled || !item.canChangeQuantidade}
                  value={this.state.focused === "conversao" && +item.conversao <= 0 ? "" : item.conversao}
                  onFocus={this.saveCurrentValue("conversao")}
                  onChange={this.updateItemValue("conversao")}
                  onBlur={event => {
                    this.setState({ focused: null });

                    const value = (typeof event === "string" ? event : event.target.value).trim();
                    if (!value || isNaN(+value)) {
                      item.conversao = 0;
                    }

                    this.afterChanged("conversao", this.checkQuantidade);
                  }}
                  onPressEnter={event => {
                    if (!!item.canChangeDesconto && !isB2B) {
                      this.requestFocus("desc_perfil");
                    } else {
                      this.requestFocus("quantidade");
                    }
                  }}
                />
              </FormItem>
            </Col>
          )}
          <Col span={2}>
            <FormItem {...formItemLayout} label="Qtd.">
              <Input
                {...inputProps}
                type="number"
                min="0"
                step="0.01"
                lang="en-150"
                ref={this.saveInput("quantidade")}
                disabled={inputProps.disabled || !item.canChangeQuantidade}
                value={this.state.focused === "quantidade" && (+item.quantidade <= 0 && item.quantidade_por_fardo >= 1) ? " " : item.quantidade}
                onFocus={this.saveCurrentValue("quantidade")}
                onChange={this.updateItemValue("quantidade")}
                onBlur={event => {
                  this.setState({ focused: null });

                  const value = (typeof event === "string" ? event : event.target.value).trim();
                  if (!value || isNaN(+value)) {
                    item.quantidade = 0;
                  }

                  this.afterChanged("quantidade", this.checkQuantidade);
                }}
                onPressEnter={event => {
                  // if (!!item.canChangeDesconto && !isB2B) {
                  //   this.requestFocus("desc_perfil");
                  // } else {
                  this.requestFocus("preco_vendido");
                  // }
                }}
              />
              {/* <InputNumber
                {...inputProps}
                formatter={(value) => ` ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                //type="number"
                //min="0"
                //step="0.01"
                //lang="en-150"
                ref={this.saveInput("quantidade")}
                disabled={inputProps.disabled || !item.canChangeQuantidade}
                value={this.state.focused === "quantidade" && (+item.quantidade <= 0 && item.quantidade_por_fardo >= 1) ? " " : item.quantidade}
                onFocus={this.saveCurrentValue("quantidade")}
                onChange={this.updateItemValue("quantidade")}
                onBlur={event => {
                  this.setState({ focused: null });

                  const value = (typeof event === "string" ? event : event.target.value).trim();
                  if (!value || isNaN(+value)) {
                    item.quantidade = 0;
                  }

                  this.afterChanged("quantidade", this.checkQuantidade);
                }}
                onPressEnter={event => {
                  if (!!item.canChangeDesconto && !isB2B) {
                    this.requestFocus("desc_perfil");
                  } else {
                    this.requestFocus("preco_vendido");
                  }
                }}
              /> */}

            </FormItem>
          </Col>
          <Col span={2}>
            <FormItem {...formItemLayout} label="Prc. Tabela">
              <Input
                {...inputProps}
                type="number"
                min="0"
                lang="en-150"
                onFocus={this.saveCurrentValue("preco_tabela")}
                value={n(!!item.preco_tabela ? item.preco_tabela : 0, 2, ".", "")}
                readOnly={true}
              />
            </FormItem>
          </Col>
          {!isB2B && (
            <Col span={2}>
              <FormItem {...formItemLayout} label="Desc. Perfil">
                <Input
                  {...inputProps}
                  type="number"
                  min="0"
                  lang="en-150"
                  ref={this.saveInput("desc_perfil")}
                  className={classnames({
                    "input-campanha": item.hasCampanha,
                    "input-error": item.descontoErrors.indexOf(`desc_perfil`) !== -1,
                  })}
                  disabled={inputProps.disabled || !item.canChangeDesconto}
                  value={item.desc_perfil}
                  onFocus={this.saveCurrentValue("desc_perfil")}
                  onChange={this.updateItemValue("desc_perfil", () => item.calculaPreco())}
                  onBlur={this.checkDesconto}
                  onPressEnter={event => {
                    if (!!item.canChangePreco) {
                      this.requestFocus("preco_vendido");
                    } else if (valid) {
                      this.addItem();
                    }
                  }}
                />
              </FormItem>
            </Col>
          )}
          <Col span={2}>
            <FormItem {...formItemLayout} label="Preço Unit.">
              <Input
                {...inputProps}
                type="number"
                min="0"
                lang="en-150"
                ref={this.saveInput("preco_vendido")}
                className={classnames({
                  "input-campanha": item.hasCampanha,
                })}
                readOnly={inputProps.readOnly || !item.canChangePreco}
                value={n(!!item.preco_vendido ? item.preco_vendido : 0, 3, ".", "")}
                onFocus={this.saveCurrentValue("preco_vendido")}
                onChange={this.updateItemValue(
                  "preco_vendido",
                  () => item.calculaDesconto(),
                  v => maskedInputValue(v, 3)
                )}
                onBlur={this.checkPreco}
                onPressEnter={event => {
                  if (valid && this.checkPreco()) this.addItem();
                }}
              />
            </FormItem>
          </Col>
          <Col span={isB2B ? 2 : 2}>
            <FormItem {...formItemLayout} label="Total Item">
              <Input
                {...inputProps}
                type="number"
                min="0"
                lang="en-150"
                value={n(!!item.valor_total ? item.valor_total : 0, 2, ".", "")}
                readOnly={true}
              />
            </FormItem>
          </Col>
          <Col span={1}>
            <FormItem style={{ marginTop: 19 }}>
              <Button
                size="small"
                type="primary"
                icon="plus-circle"
                style={{
                  display: "block",
                  marginTop: -1,
                  width: "calc(100% - 4px)",
                  minWidth: 32,
                  height: 29,
                  padding: "3px 8px",
                }}
                onClick={this.addItem}
                disabled={!valid}
              />
            </FormItem>
          </Col>
        </Row>
        {!inputProps.disabled && (
          <Row gutter={12} style={{ position: "relative", top: -6, padding: "0 2px 3px" }}>
            <Col span={4}>
              <Checkbox
                {...inputProps}
                value={1}
                checked={+item.desc_geren > 0 && item.perc_desc_gerencial > 0}
                // disabled={
                //   inputProps.disabled ||
                //   !item.canChangeDesconto ||
                //   +item.policy.desc_geren <= 0 ||
                //   +item.perc_desc_gerencial === 0
                // }
                disabled={
                  inputProps.disabled ||
                  !item.canChangeDesconto ||
                  +item.perc_desc_gerencial <= 0
                }
                onChange={event => {
                  //item.desc_geren = event.target.checked ? Math.max(+item.policy.desc_geren, 0) : 0;
                  item.desc_geren = event.target.checked ? Math.max(+item.perc_desc_gerencial, 0) : 0;
                  item.calculaPreco();
                }}>
                Conta Corrente?
              </Checkbox>
            </Col>
            <Col span={4}>
              <Checkbox
                {...inputProps}
                value={1}
                checked={!!item.campanha && +item.campanha.desconto > 0}
                // disabled={
                //   inputProps.disabled ||
                //   !item.canChangeDesconto ||
                //   +item.policy.desc_geren <= 0 ||
                //   +item.perc_desc_gerencial === 0
                // }
                disabled={
                  inputProps.disabled ||
                  !item.canChangeDescCamp ||
                  this.tipoDescontoCampanha === "PRECO_POSICIONADO"
                }
                onChange={event => {
                  //item.desc_geren = event.target.checked ? Math.max(+item.policy.desc_geren, 0) : 0;
                  if (!!item.campanha) {
                    item.desc_camp = event.target.checked ? Math.max(+item.campanha.base_campanha, 0) : 0;
                    item.campanha.desconto = event.target.checked ? Math.max(+item.campanha.base_campanha, 0) : 0;
                  }
                  item.calculaPreco();
                }}>
                Desc. Camp?
              </Checkbox>
            </Col>
            <Col span={16} className="col-tags">
              <ProdutoExtra item={item} pedido={pedido} />
            </Col>
          </Row>
        )}
        {(pedido.prev_cashback > 0 &&
          <Row>
            <Button
              style={{ fontSize: 22, width: "100%", minHeight: "40px", marginBottom: 10 }}
              type={"primary"}
              ghost={false}
              size="large">
              <Icon type="sync" spin={true} style={{ marginRight: 5, fontWeight: "bold" }} />
              Previsão Cashback R$ {n(pedido.prev_cashback, 2)}
            </Button>
          </Row>
        )}
      </Form>
    );
  }
  _inputs = {};
}
