import React, { Component } from "react";
import { autorun } from "mobx";
import { observer } from "mobx-react";

import { TableDataSource } from "components/FullTable";
import ChooserModal from "components/ChooserModal";

const rowKey = item => `${item.CampKit.codigo_kit}:${item.CampKit.operacao_frete}`;

@observer
export default class ChooserCombo extends Component {
  constructor(props) {
    super(props);

    this.ds = new TableDataSource("/pedidos/kits/000000-00-00/F/C/000000/000000");

    this.state = {
      visible: false,
      onChoose: null,
    };
  }
  componentDidMount() {
    this._watchDS = autorun(
      () => {
        const { data } = this.props.pedido;
        const codigo_cliente = `${data.codigo_cliente}`.padStart(6, "0");
        const loja_cliente = !!data.loja_cliente ? `${data.loja_cliente}`.padStart(2, "0") : "01";
        const filial_faturamento = !!data.filial_faturamento ? `${data.filial_faturamento}`.padStart(2, "0") : "01";
        const tipo_transp = !!this.props.pedido.tipoFreteTabela ? this.props.pedido.tipoFreteTabela : "C";
        const op_logistica = !!data.op_logistica ? data.op_logistica : "F";
        const cliente_retira = !!data.tipo_frete ? (data.tipo_frete === "C" ? "NAO" : "SIM") : "NAO";
        const perfil_auto = !!this.props.pedido?.policy?.data?.cod_perfil_auto ? this.props.pedido?.policy?.data?.cod_perfil_auto : this.props.pedido?.policy?.data.cod_perfil_auto;
        const perfil_imob = !!this.props.pedido?.policy?.data?.cod_perfil_imob ? this.props.pedido?.policy?.data?.cod_perfil_imob : this.props.pedido?.policy?.data.cod_perfil_imob;
        this.ds.uri = "/pedidos/combos/" + [codigo_cliente, loja_cliente, filial_faturamento].join("-") + "/" + op_logistica + "/" + cliente_retira + "/" + perfil_imob + "/" + perfil_auto + "/" + tipo_transp;
        //this.ds.uri = "/pedidos/kits/" + [codigo_cliente, loja_cliente, filial_faturamento].join("-") + "/" + op_logistica + "/" + cliente_retira + "/" + tipo_transp;
      },
      { delay: 60 }
    );
  }
  componentWillUnmount() {
    if (!!this._watchDS) this._watchDS();
  }
  onSelectRow = (...args) => {
    const { onChoose } = this.state;
    if (!!onChoose && typeof onChoose === "function") {
      onChoose(...args);
    }
    this.close();
  };
  open = (onChoose = null) => {
    this.setState({ visible: true, onChoose });
  };
  close = () => {
    this.setState({ visible: false });
  };
  render() {
    const columns = [
      {
        title: "Código",
        key: "codigo_combo",
        dataIndex: "CampKit.codigo_combo",
        width: "10%",
      },
      {
        title: "Descrição",
        key: "titulo_kit",
        dataIndex: "CampKit.titulo_combo",
        className: "text-left",
      },
    ];

    return (
      <ChooserModal
        visible={this.state.visible}
        rowKey={rowKey}
        columns={columns}
        dataSource={this.ds}
        title="Selecione um Combo"
        errorMessage="Não foi possível obter seus combos no momento"
        searchPlaceholder="Busque pela descrição do combo"
        onSelectRow={this.onSelectRow}
        onCancel={this.close}
        width={990}
        {...this.props}
      />
    );
  }
}
