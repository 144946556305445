import { Form } from "antd";
import memoize from "mem";
import moment from "moment";

export function humanFileSize(bytes, si = true) {
  const thresh = si ? 1000 : 1024;
  if (Math.abs(bytes) < thresh) {
    return bytes + " B";
  }
  const units = si
    ? ["kb", "mb", "gb", "tb", "pb", "eb", "zb", "yb"]
    : ["KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"];
  let u = -1;
  do {
    bytes /= thresh;
    ++u;
  } while (Math.abs(bytes) >= thresh && u < units.length - 1);
  return bytes.toFixed(1) + " " + units[u];
}

export function calcPMP(dias) {
  if (+dias < 1) {
    return 0;
  }

  // de 7 em 7 dias
  if (+dias % 7 >= 4) {
    return Math.ceil(+dias / 7) * 7;
  }

  return +dias;
}

export function regraDescontoEscala(regraEscala = null, otherDesconto) {
  let desconto = otherDesconto;
  if (!!regraEscala && Array.isArray(regraEscala) && regraEscala.length > 0) {
    const day = new Date().getDate();
    for (let i = 0; i < regraEscala.length; i++) {
      const ds = +regraEscala[i].ds;
      const de = +regraEscala[i].de;
      if (ds <= +day && de >= +day) {
        desconto = regraEscala[i].desc;
        break;
      }
    }
  }
  return +desconto;
}

export function itemSeq(a = "01") {
  if (isNumeric(a)) {
    return +a < 99 ? fz(parseInt(a, 10) + 1) : "A1";
  } else {
    const l = `${a}`[0];
    const n = parseInt(`${a}`.substr(1), 10);
    if (n < 9) {
      return l + "" + (n + 1);
    } else {
      return String.fromCharCode(l.charCodeAt() + 1) + "1";
    }
  }
}

export function lastSeq(s = []) {
  const si = k => (!isNumeric(k) ? parseInt(`${k}`[0].charCodeAt() + "" + `${k}`.substr(1), 10) : +k);
  return s.reduce((last, k) => {
    if (si(k) > si(last)) {
      return k;
    }
    return last;
  }, "00");
}

export function getViewportSize() {
  const w = window,
    d = document,
    e = d.documentElement;
  const g = d.getElementsByTagName("body")[0];
  const width = w.innerWidth || e.clientWidth || g.clientWidth;
  const height = w.innerHeight || e.clientHeight || g.clientHeight;

  return { width, height };
}

export function flatArrayToObject(arr, key, value) {
  return arr.reduce((acc, e) => ({ ...acc, [e[key]]: e[value] }), {});
}

export function flatObjectToOptions(obj) {
  return Object.keys(obj).reduce((acc, k) => [...acc, { label: obj[k], value: k }], []);
}

export function mergeFields(formStore, changedFields) {
  const fields = Object.keys(changedFields);
  if (fields.length > 0) {
    for (let j = 0; j < fields.length; j++) {
      const field = fields[j];
      let value = changedFields[field].value || null;
      if (!!value && typeof value === "object" && moment.isMoment(value)) {
        value = value.format("YYYY-MM-DD");
      }

      formStore[field] = value;
    }
  }
  return formStore;
}

export function mapToFormField(formData) {
  return Object.keys(formData).reduce((acc, k) => {
    let value = formData[k];
    if (!!value && typeof value === "string" && /^[0-9]{2,4}-[0-9]{1,2}-[0-9]{1,2}$/i.test(value)) {
      value = moment(value, "YYYY-MM-DD");
    }

    return { ...acc, [k]: Form.createFormField({ value: !!value ? value : undefined }) };
  }, {});
}

export function uniq(arrArg) {
  return arrArg.filter((elem, pos, arr) => arr.indexOf(elem) === pos);
}

export function getData(collection = {}, key, unset = null) {
  let k = null;
  let _data = unset;
  let keys = ("" + key).split(".").reverse();

  while ((k = keys.pop())) _data = collection !== null && k in collection ? (collection = collection[k]) : unset;

  return _data;
}

export function strip(str, len = 0) {
  if (("" + str).length > len) {
    let sub = str.substr(0, Math.max(+len, 0));
    return sub.substr(0, sub.lastIndexOf(" ")) + "...";
  }

  return str;
}

export function serialize(obj, prefix = false) {
  const str = [];
  for (let p in obj) {
    if (obj.hasOwnProperty(p)) {
      let k = prefix ? prefix + "[" + p + "]" : p,
        v = obj[p];
      str.push(
        v !== null && typeof v === "object" ? serialize(v, k) : encodeURIComponent(k) + "=" + encodeURIComponent(v)
      );
    }
  }
  return str.join("&");
}

export function serializeObject(obj, prefix = false) {
  let o = [];
  for (let p in obj) {
    if (obj.hasOwnProperty(p)) {
      let k = prefix ? prefix + "[" + p + "]" : p,
        v = obj[p];
      if (v !== null && typeof v === "object") {
        o = o.concat(serializeObject(v, k));
      } else {
        o.push({ field: k, value: v });
      }
    }
  }
  return o;
}

export function srid() {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }

  return `${s4()}-${s4()}-${s4()}`;
}

export function findLabelByValue(items, value, placeholder = null) {
  for (let j = 0, jl = items.length; j < jl; j++) {
    if (items[j].value === value) {
      return items[j].label;
    }
  }

  return placeholder;
}

export function isEmpty(a) {
  return a === null || a === "";
}

export const fz = memoize(function fz(n) {
  return +n < 10 ? `0${+n}` : `${n}`;
});

export function isNumeric(n) {
  return !isNaN(parseFloat(n)) && isFinite(n);
}

export function validateEAN13(ean) {
  const checkSum = `${ean}`.split("").reduce((p, v, i) => (i % 2 === 0 ? p + 1 * v : p + 3 * v), 0);
  return checkSum % 10 === 0;
}

export function validateCNPJ(s) {
  let cnpj = s.replace(/[^\d]+/g, "");

  // Valida a quantidade de caracteres
  if (cnpj.length !== 14) return false;

  // Elimina inválidos com todos os caracteres iguais
  if (/^(\d)\1+$/.test(cnpj)) return false;

  // Cáculo de validação
  let t = cnpj.length - 2,
    d = cnpj.substring(t),
    d1 = parseInt(d.charAt(0)),
    d2 = parseInt(d.charAt(1)),
    calc = x => {
      let n = cnpj.substring(0, x),
        y = x - 7,
        s = 0,
        r = 0;

      for (let i = x; i >= 1; i--) {
        s += n.charAt(x - i) * y--;
        if (y < 2) y = 9;
      }

      r = 11 - (s % 11);
      return r > 9 ? 0 : r;
    };

  return calc(t) === d1 && calc(t + 1) === d2;
}

export function validateCPF(cpf) {
  cpf = cpf.replace(/[^\d]+/g, '');

  if (cpf.length !== 11) return false;

  if (/^(\d)\1+$/.test(cpf)) return false;

  let add = 0;
  for (let i = 0; i < 9; i++)
    add += parseInt(cpf.charAt(i)) * (10 - i);

  let rev = 11 - (add % 11);
  if (rev === 10 || rev === 11) rev = 0;
  if (rev !== parseInt(cpf.charAt(9))) return false;

  add = 0;
  for (let i = 0; i < 10; i++)
    add += parseInt(cpf.charAt(i)) * (11 - i);

  rev = 11 - (add % 11);
  if (rev === 10 || rev === 11) rev = 0;
  if (rev !== parseInt(cpf.charAt(10))) return false;

  return true;
}

export function sy(...args) {
  return JSON.stringify(...args);
}

export const filterDate = memoize(function filterDate(d, glue = "-") {
  d = d.replace(/[/\\]/gi, "-");
  if (/^[0-9]{1,2}-[0-9]{1,2}(-[0-9]{2,4})?$/i.test(d)) {
    d = d.split("-").reverse();
  } else if (/^[0-9]{2,4}-[0-9]{1,2}-[0-9]{1,2}$/i.test(d)) {
    d = d.split("-");
  } else {
    return null;
  }

  if (d.length === 3 && d[0].length < 4) {
    d[0] = "20" + d[0].substr(-2);
  } else if (d.length === 2) {
    d = [`${new Date().getFullYear()}`, ...d];
  }

  return d.map(fz).join(glue);
});

export const dateFn = memoize(function dateFn(data) {
  return !!data && typeof data === "string" && /^[0-9]{2,4}-[0-9]{1,2}-[0-9]{1,2}$/i.test(data)
    ? moment(data, "YYYY-MM-DD")
    : null;
});

export const dateFmt = memoize(function dateFmt(time) {
  if (+time < 1) return null;

  const d = new Date(time * 1000);
  return fz(d.getDate()) + "/" + fz(d.getMonth() + 1) + "/" + d.getFullYear();
});

export const dateFormat = memoize(function dateFormat(d, showHours = false) {
  if (!d) return null;

  const [date, hours = null] = d.split(" ");
  if (!!date) {
    return (
      date.split("-").reverse().join("/") +
      " " +
      (showHours && !!hours ? hours.split(":").slice(0, 2).join(":") : "")
    ).trim();
  }

  return null;
});

export function generateUUID() {
  let d = new Date().getTime();
  const uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, c => {
    let r = (d + Math.random() * 16) % 16 | 0;
    d = Math.floor(d / 16);

    return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
  });
  return uuid;
}

export function number_format(number, decimals, dec_point, thousands_sep) {
  // original by: Jonas Raoni Soares Silva (http://www.jsfromhell.com)
  // improved by: Kevin van Zonneveld (http://kevin.vanzonneveld.net)
  number = (number + "").replace(",", "").replace(" ", "");
  var n = !isFinite(+number) ? 0 : +number,
    prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
    sep = typeof thousands_sep === "undefined" ? "," : thousands_sep,
    dec = typeof dec_point === "undefined" ? "." : dec_point,
    s = "",
    toFixedFix = function (n, prec) {
      var k = Math.pow(10, prec);
      return "" + Math.round(n * k) / k;
    };
  // Fix for IE parseFloat(0.55).toFixed(0) = 0;
  s = (prec ? toFixedFix(n, prec) : "" + Math.round(n)).split(".");
  if (s[0].length > 3) {
    s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
  }
  if ((s[1] || "").length < prec) {
    s[1] = s[1] || "";
    s[1] += new Array(prec - s[1].length + 1).join("0");
  }
  return s.join(dec);
}

export function n(a, d = 2, s = ",", m = ".") {
  return number_format(a, d, s, m);
}

export function moneyFixed(a, d = 2) {
  const na = number_format(a, d, ".", "");
  return +na;
}

export function handleMoneyFormat(value) {
  const formattedValue = 'R$ ' + value.toFixed(2).replace('.', ',');
  // Reverter ponto e vírgula
  const invertedValue = formattedValue.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');

  return invertedValue;
}

export function handleMoneyFormatDescont(value) {
  console.log(typeof value)
  console.log(value)
  return value;
}

export function maskedInputValue(value, d = 2) {
  if (!value) {
    return null;
  }
  const digits = `${value}`.match(/\d/g) || ["0"];
  if (digits.length === 1) digits.unshift("0");
  digits.splice(digits.length - +d, 0, ".");

  return Number(digits.join("")).toFixed(+d);
}

export function isDarkColor(color) {
  let r, g, b;

  if (color.match(/^rgb/)) {
    color = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/);

    r = +color[1];
    g = +color[2];
    b = +color[3];
  } else {
    color = +("0x" + color.slice(1).replace(color.length < 5 && /./g, "$&$&"));

    r = color >> 16;
    g = (color >> 8) & 255;
    b = color & 255;
  }

  const yiq = (r * 299 + g * 587 + b * 114) / 1000;
  return yiq >= 128;
}

export function generateRandomNumber() {
  const min = 1000000000; // Menor número de 6 dígitos
  const max = 9999999999; // Maior número de 6 dígitos
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export function truncate(str, n) {
  return (str.length > n) ? str.slice(0, n) : str;
};

export function formataCPF(cpf) {
  cpf = cpf.replace(/[^\d]/g, "");
  return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
}

export const MESES = {
  '01': "Janeiro",
  '02': "Fevereiro",
  '03': "Março",
  '04': "Abril",
  '05': "Maio",
  '06': "Junho",
  '07': "Julho",
  '08': "Agosto",
  '09': "Setembro",
  '10': "Outubro",
  '11': "Novembro",
  '12': "Dezembro",
}