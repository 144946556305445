import React, { Component, Fragment } from "react";
import { Form, Button } from "antd";
import { observer } from "mobx-react";

import withStore from "withStore";
import PageTitle from "components/PageTitle";
import FullTable, { TableDataSource, TextFilter, SearchForm, Toolbar, WrappedValue } from "components/FullTable";
import RadioGroup from "antd/lib/radio/group";
import RadioButton from "antd/lib/radio/radioButton";
import moment from "moment";
import classNames from "classnames";
import Alerts from "screens/common/Components/Alerts";

const FormItem = Form.Item;

const decorateRows = (item, index) => {
  return (
    classNames("base-table--row", {
      "base-table--row__odd": index % 2 !== 0,
      "base-table--row__sucess": item[`volume_antes`] < item[`volume_depois`],
      "base-table--row__danger": item[`volume_antes`] > item[`volume_depois`],
      "base-table--row__invalid": item[`volume_antes`] === item[`volume_depois`],
    }))
};

@observer
class ProdutosTrabalhadoPromotoria extends Component {
  constructor(props) {
    super(props);

    const isMop = props.store.isOperador && props.match.url.indexOf("mop") !== -1;

    this.ds = new TableDataSource({
      name: "Produtos Trabalhados Promotoria",
      uri: "/r/produtos-trabalhados-promotoria",
      controlled: false,
    });

    this.state = {
      isMop,
      quantidade: moment().add(1, 'month').diff(moment(), 'days')
    };
  }
  renderSearchForm = ({ dataSource, onUpdateDataSource }) => {
    return (
      <Fragment>
        {this.renderAlerts()}
        <SearchForm
          layout="inline"
          dataSource={dataSource}
          onUpdateDataSource={onUpdateDataSource}
          filterFields={{
            quantidade: "text",
          }}
          filterParser={{
            quantidade: filter => ({ ...filter, type: "text" }),
          }}
          defaultFilters={{ quantidade: 1 }}>
          {({ form }) => (
            <Fragment>
              <FormItem>
                {form.getFieldDecorator("quantidade")(
                  <RadioGroup
                    onChange={({ target: { value } }) => this.setState({ quantidade: value })}
                    value={this.state.quantidade}
                    buttonStyle="solid"
                  >
                    {Array.from({ length: 12 }, (_, i) => (
                      <RadioButton
                        key={`quantidade-${i + 1}`}
                        value={i + 1}
                      >
                        {`${i + 1} ${i === 0 ? 'mês' : 'meses'}`}
                      </RadioButton>
                    ))}
                  </RadioGroup>
                )}
              </FormItem>
              <FormItem>
                <Button type="danger" htmlType="submit" icon="search" />
              </FormItem>
              <FormItem style={{ float: "right", margin: 0 }}>
                <Toolbar.Button
                  disabled={dataSource.isLoading}
                  size="default"
                  icon="reload"
                  ghost={true}
                  onClick={() => dataSource.fetch()}>
                  Atualizar
                </Toolbar.Button>
              </FormItem>
            </Fragment>
          )}
        </SearchForm>
      </Fragment>
    );
  };

  renderAlerts = () => {
    const alerts = [
      {
        message: "Volume caiu!",
        type: "error",
      },
      {
        message: "Mantendo volume!",
        type: "warning",
      },
      {
        message: "Volume subiu!",
        type: "success",
      },
    ]
    return (
      <Alerts alerts={alerts} />
    );
  };

  render() {
    const columns = [
      {
        title: "Promotoria",
        dataIndex: "promotoria",
        key: "promotoria",
        sorter: false,
        filteredValue: this.ds.filterValue("promotoria"),
        filterDropdown: props => <TextFilter placeholder="Filtrar por Promotoria" {...props} />,
        filterParser: filter => ({ ...filter, field: "promotoria", value: `%${filter.value}`, op: "LIKE" }),
        width: "9.08%",
      },
      {
        title: "Data Promotoria",
        dataIndex: "data",
        dataType: "date",
        key: "data",
        width: "9.08%",
      },
      {
        title: "Cod. Cliente",
        dataIndex: "codigo",
        key: "codigo",
        sorter: false,
        filteredValue: this.ds.filterValue("codigo"),
        filterDropdown: props => <TextFilter placeholder="Filtrar por codigo" {...props} />,
        filterParser: filter => ({ ...filter, field: "codigo", value: `%${filter.value}`, op: "LIKE" }),
        width: "9.08%",
      },
      {
        title: "Cliente",
        dataIndex: "cliente",
        key: "cliente",
        sorter: false,
        filteredValue: this.ds.filterValue("cliente"),
        filterDropdown: props => <TextFilter placeholder="Filtrar por cliente" {...props} />,
        filterParser: filter => ({ ...filter, field: "cliente", value: `%${filter.value}`, op: "LIKE" }),
        width: "9.08%",
      },
      {
        title: "Cód. Repre",
        dataIndex: "vendedor",
        key: "vendedor",
        sorter: false,
        filteredValue: this.ds.filterValue("vendedor"),
        filterDropdown: props => <TextFilter placeholder="Filtrar por codigo" {...props} />,
        filterParser: filter => ({ ...filter, field: "vendedor", value: `%${filter.value}`, op: "LIKE" }),
        width: "9.08%",
      },
      {
        title: "Representante",
        dataIndex: "representante",
        key: "representante",
        sorter: false,
        filteredValue: this.ds.filterValue("representante"),
        filterDropdown: props => <TextFilter placeholder="Filtrar por Representante" {...props} />,
        filterParser: filter => ({ ...filter, field: "representante", value: `%${filter.value}`, op: "LIKE" }),
        width: "9.08%",
      },
      {
        title: "Produto",
        dataIndex: "produto",
        key: "produto",
        width: "9.08%",
      },
      {
        title: "Classe 4",
        dataIndex: "classe_4",
        key: "classe_4",
        width: "9.08%",
      },
      {
        title: "Classe 6",
        dataIndex: "classe_6",
        key: "classe_6",
        width: "9.08%",
      },
      {
        title: "Vlr. antes",
        dataIndex: "valor_antes",
        key: "valor_antes",
        width: "9.08%",
        render: n => <WrappedValue value={n} type="money" />,
      },
      {
        title: "Vlm. antes",
        dataIndex: "volume_antes",
        key: "volume_antes",
        width: "9.08%",
      },
      {
        title: "Vlr. depois",
        dataIndex: "valor_depois",
        key: "valor_depois",
        width: "9.08%",
        render: n => <WrappedValue value={n} type="money" />,
      },
      {
        title: "Vlm. depois",
        dataIndex: "volume_depois",
        key: "volume_depois",
        width: "9.08%",
      },
    ];

    return (
      <div className="nfs-screen">
        <PageTitle title={this.ds.name}>
        </PageTitle>
        <FullTable
          actionBar={<Toolbar onClean={() => this.setState({ quantidade: 31 })} />}
          className="full-table__compact"
          rowClassName={decorateRows}
          beforeComponent={this.renderSearchForm}
          loading={this.props.store.isLoading}
          rowKey={item => `${item.promotoria}${item.produto}`}
          columns={columns}
          dataSource={this.ds}
          errorMessage="Não foi possível obter seus cursos e treinamentos no momento"
          onError={() => { this.props.store.isLoading = false; this.ds.isLoading = false }}
        />
      </div>
    );
  }
}

export default withStore(ProdutosTrabalhadoPromotoria);