import React, { Component, Fragment } from "react";
import { Modal, Table, Tag, Form, Input, Checkbox, Icon } from "antd";
import { toJS } from "mobx";
import styled from "styled-components";
import { observer } from "mobx-react";
import classnames from "classnames";

import { number_format, n } from "@util";
import { Toolbar, WrappedValue, mapColumn } from "components/FullTable";
import { PRECO_POSICIONADO } from "stores/PedidoStore/Item";

import "./KitTable.less";
import { PedidoError } from "stores/PedidoStore";

const FormItem = Form.Item;
const Search = Input.Search;

const nf = (n, d = 2) => number_format(+n, d, ".", "");

const rowKey = item => `${item.campKit.codigo_kit}:${item.codigo_produto}:${item.classe_produto}`;

const TagCashBack = styled.div`
  display: inline-block;
  background-color: #195488;
  color: #fff;
  font-weight: 600;
  font-size: 11px;
  line-height: 11px;
  padding: 3px 4px;
  border-radius: 4px;
  margin-right: 3px;
  cursor: default;
`;

const decorateRows = (item, index) =>
  classnames("base-table--row kit-table--row", {
    "base-table--row__odd kit-table--row__odd": index % 2 !== 0,
    "base-table--row__selected kit-table--row__selected": +item.quantidade > 0 && !!item.isValid,
    "base-table--row__invalid kit-table--row__invalid": +item.quantidade > 0 && !item.isValid,
  });

@observer
export default class KitTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      filtered: false,
      searchText: "",
      current: {},
      focused: null,
    };
  }
  saveCurrentValue = (item, field) => event => {
    const { current } = this.state;

    const key = rowKey(item);
    const value = typeof event === "string" ? event : event.target.value;

    this.setState({ focused: field + ":" + key, current: { ...current, [key]: { ...current[key], [field]: value } } });
  };
  updateItemValue = (item, field, onChange = null, tr = v => v) => event => {
    item[field] = tr(typeof event === "string" ? event : event.target.value);

    if (!!onChange && typeof onChange === "function") {
      onChange(field, toJS(item[field]));
    }
  };
  hasChanged = (item, field) => {
    const { current } = this.state;
    const key = rowKey(item);

    return (
      !current[key] || (!!current[key] && (!current[key][field] || `${current[key][field]}` !== `${toJS(item[field])}`))
    );
  };
  checkQuantidade = item => {
    if (this.hasChanged(item, "quantidade")) {
      const { kit, onPedidoError } = this.props;
      try {
        item.checkQuantidade();
      } catch (err) {
        onPedidoError(err.name === "PedidoError" && !!err.message ? err.message : `Quantidade inválida!`);
      } finally {
        kit.debouncedCalculaMetas();
      }
    }
  };
  checkDesconto = (item) => {
    if (this.hasChanged(item, "desc_perfil")) {
      const { onPedidoError, kit } = this.props;

      try {
        item.checkDesconto();
        for (let i = 0, ic = kit.items.length; i < ic; i++) {
          kit.items[i].calculaPreco();
        }
      } catch (err) {
        item.calculaPreco();

        onPedidoError(
          err.name === "PedidoError" && !!err.message ? err.message : `Desconto não permitido para o perfil!`
        );
      }
    }
  };
  checkPreco = (item) => {
    if (this.hasChanged(item, "preco_vendido")) {
      const { onPedidoError } = this.props;

      try {
        item.checkPreco();
        if ((!!item.hasCampKit && item.tipoDescontoCampanha === PRECO_POSICIONADO) &&
          +item.preco_vendido < item.campKit.preco_kit) {
          throw new PedidoError(
            `Produto com preço posicionado a R$ ${n(+item.campKit.preco_kit, 3)}. 
            Permitido vender por esse valor mínimo ou valor maior!`
          );
        }
      } catch (err) {
        item.calculaPreco();

        onPedidoError(
          err.name === "PedidoError" && !!err.message ? err.message : `ERRO!`,
        );

        return false;
      } finally {
        this.checkDesconto(item);
      }
    }
    return true;
  };
  applyDescPerfil = event => {
    event.preventDefault();

    const { kit } = this.props;
    if (kit.tipoDesconto !== PRECO_POSICIONADO && kit.items.length > 0) {
      const minDescPerfil = +kit.items[0].desc_perfil;
      const onOk = () => {
        for (let i = 0, ic = kit.items.length; i < ic; i++) {
          kit.items[i].desc_perfil = minDescPerfil;
          kit.items[i].calculaPreco();
        }
      };

      Modal.confirm({
        title: `Você confirma o nivelamento de desconto de perfil em ${minDescPerfil}%?`,
        onOk,
      });
    }
  };
  filterData = () => {
    const { searchText = "" } = this.state;
    if (searchText.trim() !== "") {
      this.setState({ filtered: true });
    } else {
      this.setState({ filtered: false });
    }
  };
  render() {
    const {
      kit,
      items,
      className,
      wrapperClassName,
      rowClassName = decorateRows,
      loading = false,
      locale = {},
      bordered = true,
      size = "middle",
      ...tableProps
    } = this.props;

    const { searchText = "", filtered = false } = this.state;

    let columns = [
      {
        title: "Código",
        key: "codigo_produto",
        dataIndex: "codigo_produto",
        width: "9%",
      },
      {
        title: "Produto",
        key: "descricao",
        render: (_, item) => (
          <div style={{ display: "flex" }}>
            {item.tintometrico !== "SIM" && <Tag color="#AC1A22">Tintométrico</Tag>}
            {item.venda_liberada !== "SIM" && <Tag color="#AC1A22">Venda Controlada</Tag>}
            {item.possui_cashback === 'SIM' && <TagCashBack> <Icon type="sync" spin={true} style={{ marginRight: 5, fontWeight: "bold" }} />CASHBACK</TagCashBack>}
            <WrappedValue style={{ minWidth: "auto", flex: 1 }} value={item.descricao} type="text" />
            <em style={{ fontSize: 10, fontWeight: "600" }}>Qtd. Emb: {Math.max(+item.quantidade_por_fardo, 1)}</em>
          </div>
        ),
        dataIndex: "descricao",
        className: "text-left",
      },
      {
        title: (
          <Fragment>
            Qtd. Min. <em title="em Latas">(LT)</em>
          </Fragment>
        ),
        key: "qtd_min",
        dataIndex: "campKit.qtd_min",
        width: "10.5%",
      },
      {
        title: "Preço",
        key: "preco_vendido",
        dataIndex: "preco_vendido",
        render: (v, item) => {
          if (!!item.hasCampKit && item.tipoDescontoCampanha === PRECO_POSICIONADO) {
            return (
              <Input
                size="small"
                type="number"
                min="0"
                lang="en-150"
                className={classnames({
                  "input-error": item.descontoErrors.indexOf(`preco_vendido`) !== -1,
                })}
                value={item.preco_vendido}
                onFocus={this.saveCurrentValue(item, "preco_vendido")}
                onChange={this.updateItemValue(item, "preco_vendido")}
                onBlur={() => {
                  this.setState({ focused: null });
                  this.checkPreco(item);
                }}
              />
            )
          } else {
            return <WrappedValue value={nf(+v, 2)} type="text" />
          }
        },
        width: "9.5%",
        className: "text-center td-input",
      },
      {
        title: <span title="% de Desconto">Desc.</span>,
        key: "desconto_kit",
        dataIndex: "campKit.desconto_kit",
        render: n => <WrappedValue value={`${n}%`} type="text" />,
        width: "7%",
      },
      {
        title: (
          <Fragment>
            Qtd. <em title="em Latas">(LT)</em>
          </Fragment>
        ),
        key: "quantidade",
        dataIndex: "quantidade",
        render: (_, item) => (
          <Input
            size="small"
            type="number"
            min="0"
            step="1"
            lang="en-150"
            disabled={!item.canChangeQuantidade}
            value={this.state.focused === `quantidade:${rowKey(item)}` && +item.quantidade <= 0 ? "" : item.quantidade}
            onFocus={this.saveCurrentValue(item, "quantidade")}
            onChange={this.updateItemValue(item, "quantidade", () => kit.debouncedCalculaMetas())}
            onBlur={event => {
              this.setState({ focused: null });

              const value = (typeof event === "string" ? event : event.target.value).trim();
              if (!value || isNaN(+value)) {
                item.quantidade = 0;
              }

              this.checkQuantidade(item);
            }}
          />
        ),
        width: "8.5%",
        className: "text-center td-input",
      },
    ];

    if (kit.tipoDesconto !== PRECO_POSICIONADO) {
      columns.push({
        title: (
          <a
            href="#desc-perfil-all"
            onClick={this.applyDescPerfil}
            title="Clique para nivelar a % de Desc. Perfil dos itens">
            % Perfil
          </a>
        ),
        key: "desc_perfil",
        dataIndex: "desc_perfil",
        render: (_, item) => (
          <Input
            size="small"
            type="number"
            min="0"
            lang="en-150"
            className={classnames({
              "input-error": item.descontoErrors.indexOf(`desc_perfil`) !== -1,
            })}
            disabled={!item.canChangeQuantidade}
            value={item.desc_perfil}
            onFocus={this.saveCurrentValue(item, "desc_perfil")}
            onChange={this.updateItemValue(item, "desc_perfil")}
            onBlur={event => {
              this.setState({ focused: null });
              this.checkDesconto(item);
            }}
          />
        ),
        width: "8%",
        className: "text-center td-input",
      });
    }

    if (kit.canUseDescGeren) {
      columns.push({
        title: (
          <Checkbox
            value={1}
            checked={kit.validItemsTotal > 0 && kit.descGerenQtd >= /*kit.validItemsTotal*/ 1}
            disabled={kit.validItemsTotal <= 0}
            onChange={event => kit.toggleAllDescGeren(event)}>
            <span title="Conta corrente">Conta corrente</span>
          </Checkbox>
        ),
        key: "desc_geren",
        dataIndex: "desc_geren",
        render: (_, item) => (
          <Checkbox
            value={1}
            checked={+item.desc_geren > 0}
            disabled={!item.canChangeDesconto || +item.quantidade <= 0 || item.perc_desc_gerencial <= 0}
            onChange={event => {
              //item.desc_geren = !!event.target.checked && item.perc_desc_gerencial > 0 ? Math.max(+item.policy.desc_geren, 0) : 0;
              item.desc_geren = !!event.target.checked && item.perc_desc_gerencial > 0 ? Math.max(+item.perc_desc_gerencial, 0) : 0;
              item.calculaPreco();
            }}
          />
        ),
        width: "12%",
        className: "text-center td-desc",
      });
    }

    return (
      <div className={classnames(`base-table-wrapper kit-table-wrapper`, wrapperClassName)}>
        <Form
          layout="inline"
          className={"full-table--search-form"}
          onSubmit={event => {
            event.preventDefault();
            this.filterData();
          }}>
          <Fragment>
            <FormItem style={{ flex: 1 }}>
              <Search
                placeholder="Buscar por um produto ou código"
                value={searchText}
                onChange={event => this.setState({ searchText: event.target.value })}
                onSearch={() => setTimeout(this.filterData, 30)}
                enterButton
              />
            </FormItem>
            {!!filtered && (
              <FormItem>
                <Toolbar.Button
                  size="default"
                  icon="close"
                  ghost={true}
                  onClick={() => {
                    this.setState({ searchText: "", filtered: false });
                  }}
                />
              </FormItem>
            )}
          </Fragment>
        </Form>
        <Table
          className={classnames(
            `base-table base-table__compact kit-table kit-table__compact`,
            { "base-table__loading kit-table__loading": !!loading },
            className
          )}
          pagination={false}
          rowKey={rowKey}
          loading={!!loading}
          bordered={bordered}
          columns={columns.map(mapColumn)}
          rowClassName={rowClassName}
          dataSource={
            !!filtered
              ? kit.items.filter(
                item =>
                  item.codigo_produto.endsWith(searchText) ||
                  item.descricao.toLowerCase().includes(searchText.toLowerCase())
              )
              : kit.items
          }
          scroll={{ y: kit.metas.length > 0 ? 232 : 348 }}
          locale={{
            filterTitle: "Filtrar",
            filterConfirm: "OK",
            filterReset: "Limpar",
            emptyText: "Cliente Sem permissão para visualizar os itens desse kit no momento, contate o setor comercial !",
            ...locale,
          }}
          size={size}
          {...tableProps}
        />
      </div>
    );
  }
}
