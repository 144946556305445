import React, { Fragment, Component } from "react";
import { Checkbox, Table, Modal, Icon } from "antd";
import _isObject from "lodash/isObject";
import { observer } from "mobx-react";
import styled from "styled-components";

import { n } from "@util";

function cv(v) {
  return v;
}

function p(v) {
  return !!v ? `${v}`.replace(".", ",") : "0";
}

function percentParseDesc(v) {
  return `${p(v)}%`;
}

function decoratePolicyGroup(da, di) {
  if (+da > 0 && +di > 0) {
    return "success";
  }
  if (+da > 0 || +di > 0) {
    return "warning";
  }

  return "danger";
}

const TableDescEsc = ({ title, desconto, ...props }) => (
  <div style={title.toLowerCase().indexOf("automotiva") !== -1 ? { marginTop: -8, marginBottom: 21 } : {}}>
    <h4 style={{ color: "#0f73b9" }}>{title}</h4>
    <Table
      pagination={false}
      columns={[
        { title: "Do dia", dataIndex: "ds", className: "text-center" },
        { title: "Até o dia", dataIndex: "de", className: "text-center" },
        { title: "Desconto", dataIndex: "desc", className: "text-center", render: value => `${value}%` },
      ]}
      dataSource={desconto}
      rowKey={item => `${item.de}/${item.desc}`}
      size="small"
      {...props}
    />
  </div>
);

const TableDescExtra = ({ extras, ...props }) => (
  <Table
    pagination={false}
    columns={[
      { title: "Classe", dataIndex: "classe", className: "text-center" },
      { title: "Descrição", dataIndex: "desc_classe", className: "text-center" },
      { title: "Desconto", dataIndex: "desconto", className: "text-center", render: value => `${value}%` },
    ]}
    dataSource={extras}
    rowKey={item => `${item.classe}/${item.desconto}`}
    size="small"
    {...props}
  />
);

const TableDescGeren = ({ descs, ...props }) => (
  <Table
    pagination={false}
    scroll={true}
    columns={[
      { title: "Linha", dataIndex: "linha", className: "text-center" },
      { title: "Codigo", dataIndex: "produto", className: "text-center" },
      { title: "Descrição", dataIndex: "descricao", className: "text-center" },
      { title: "Desconto", dataIndex: "desconto", className: "text-center", render: value => `${value}%` },
    ]}
    dataSource={descs}
    rowKey={item => `${item.linha}/${item.produto}`}
    size="small"
    {...props}
  />
);

const PolicyGroupContainer = styled.div`
  flex: 1;
  border: 1px solid #ddd;
  background-color: ${({ status }) =>
    (status === "danger" && "#e05353") ||
    (status === "warning" && "#f7ea62") ||
    (status === "success" && "#6ad182") ||
    "#fcfcfc"};
  box-shadow: inset 0px 0px 8px
    ${({ status }) =>
      (status === "danger" && "rgba(0, 0, 0, 0.4)") ||
      (status === "warning" && "rgba(0, 0, 0, 0.2)") ||
      (status === "success" && "rgba(0, 0, 0, 0.25)") ||
      "rgba(0, 0, 0, 0.15)"};
  border-radius: 4px;
  padding: 4px 5px;
  min-width: 86px;

  &:last-child {
    margin-right: 2px;
  }

  & + & {
    margin-left: 4px;
  }
`;

const PolicyItemWrapper = styled.div.attrs({ className: "text-nowrap" })`
  font-size: 10px;
  font-weight: bold;
  line-height: 16px;
  height: 16px;
  min-width: 100%;
  letter-spacing: -0.3px;
  color: #303030;
  margin: 0;
  padding: 0;
  cursor: default;

  .policy-bar--item__link {
    cursor: pointer;
    padding-left: 0;
    padding-right: 0;

    &:hover {
      text-decoration: underline;
      color: ${props => props.theme.primary};
    }
  }

  & > .ant-checkbox-wrapper {
    float: left;
    margin-right: 3px;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #1890ff;
    border-color: #0a67ea;
  }

  & + & {
    margin-top: 3px;
  }
`;

const PolicyItem = ({ children, checked = null, value = null, onChange, ...props }) => {
  return (
    <PolicyItemWrapper {...props}>
      {checked !== null && <Checkbox checked={checked} onChange={onChange} />}
      {children}
    </PolicyItemWrapper>
  );
};

const PolicyGroup = ({
  title,
  titleProps,
  className,
  descAuto,
  descImob,
  onChange,
  parseDesc = percentParseDesc,
  ...props
}) => {
  const _descAuto = _isObject(descAuto)
    ? { value: null, field: null, ...descAuto }
    : { value: null, field: null, label: descAuto };
  const _descImob = _isObject(descImob)
    ? { value: null, field: null, ...descImob }
    : { value: null, field: null, label: descImob };

  const _descAutoChecked = _descAuto.value !== null ? +_descAuto.value > 0 : null;
  const _descImobChecked = _descImob.value !== null ? +_descImob.value > 0 : null;

  return (
    <PolicyGroupContainer
      status={
        _descAuto.field !== null && _descImob.field !== null && decoratePolicyGroup((_descAuto.label > 0 ? _descAuto.label : _descAuto.value), (_descImob.label > 0 ? _descImob.label : _descImob.value) )
      }
      {...props}>
      <PolicyItem
        title={typeof title === "string" ? title : ""}
        checked={_descAutoChecked && _descImobChecked}
        onChange={() => {
          if (_descAutoChecked && _descImobChecked) {
            onChange(_descAuto.field, _descAuto.value, "off");
            onChange(_descImob.field, _descImob.value, "off");
          } else {
            onChange(_descAuto.field, _descAuto.value, "on");
            onChange(_descImob.field, _descImob.value, "on");
          }
        }}
        value={1}
        {...titleProps}>
        {title}
      </PolicyItem>
      <PolicyItem
        title={!!_descAuto.label && typeof _descAuto.label === "string" ? _descAuto.label : ""}
        checked={_descAutoChecked}
        value={_descAuto.value}
        onChange={() => onChange(_descAuto.field, _descAuto.value)}>
        {parseDesc(_descAuto.label, "auto")}
      </PolicyItem>
      <PolicyItem
        title={!!_descImob.label && typeof _descImob.label === "string" ? _descImob.label : ""}
        checked={_descImobChecked}
        value={_descImob.value}
        onChange={() => onChange(_descImob.field, _descImob.value)}>
        {parseDesc(_descImob.label, "imob")}
      </PolicyItem>
    </PolicyGroupContainer>
  );
};

const PolicyBarContainer = styled.div`
  overflow-y: hidden;
  overflow-x: auto;
  margin: 0 0 5px;

  &::-webkit-scrollbar {
    height: 5px;
  }
  &::-webkit-scrollbar-thumb {
    min-width: 28px;
  }
`;

const PolicyBarWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0 0 5px;
`;

@observer
export default class PolicyBar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isVisiblePolicyModal: false,
      isVisibleDescExtraModal: false,
      isVisibleDescGerenModal: false,

    };
  }
  openPolicyModal = () => {
    this.setState({ isVisiblePolicyModal: true });
  };
  closePolicyModal = () => {
    this.setState({ isVisiblePolicyModal: false });
  };
  openDescExtraModal = () => {
    this.setState({ isVisibleDescExtraModal: true });
  };
  closeDescExtraModal = () => {
    this.setState({ isVisibleDescExtraModal: false });
  };
  openDescGerenModal = () => {
    this.setState({ isVisibleDescGerenModal: true });
  };
  closeDescGerenModal = () => {
    this.setState({ isVisibleDescGerenModal: false });
  };

  changeDesconto = (field, value, force = null) => {
    if (!force) {
      this.props.policy.toggle(field);
    } else {
      if (force === "on") {
        this.props.policy.toggleOn(field);
      } else {
        this.props.policy.toggleOff(field);
      }
    }
  };
  render() {
    const { policy, condicaoTaxa = 0 } = this.props;

    return (
      <Fragment>
        <PolicyBarContainer>
          <PolicyBarWrapper>
            <PolicyGroup
              title="Linha"
              descAuto="Aut."
              descImob="Imob."
              parseDesc={cv}
              style={{ flex: "0 58px", minWidth: 44 }}
            />
            <PolicyGroup
              title="Perfil"
              descAuto={policy.data.perfil_auto}
              descImob={policy.data.perfil_imob}
              parseDesc={cv}
            />
            <PolicyGroup
              title="Vol. Atingido"
              descAuto={policy.data.volume_atingido_auto}
              descImob={policy.data.volume_atingido_imob}
              parseDesc={v => n(v, 2)}
              style={{ minWidth: 76 }}
            />
            <PolicyGroup
              title="Desc. T.C."
              titleProps={{
                title: "Desconto Tinta e Complemento",
              }}
              descAuto={policy.data.desc_tinta_auto}
              descImob={policy.data.desc_tinta_imob}
              style={{ flex: "0 76px", minWidth: 64 }}
            />
            <PolicyGroup
              title="Desc. Solv."
              titleProps={{
                title: "Desconto Solvente",
              }}
              descAuto={policy.data.desc_solve_auto}
              descImob={policy.data.desc_solve_imob}
              style={{ flex: "0 76px", minWidth: 64 }}
            />
            <PolicyGroup
              title={
                <span
                  title="Desconto Logístico"
                  onClick={event => {
                    event.preventDefault();
                    if (policy.hasDescLogisEscalonado) this.openPolicyModal();
                  }}
                  className="policy-bar--item__link">
                  Desc. Logís.
                  {policy.hasDescLogisEscalonado && (
                    <Icon type="info-circle-o" style={{ marginLeft: 2, fontSize: 11 }} />
                  )}
                </span>
              }
              descAuto={policy.field("desc_logis_auto")}
              descImob={policy.field("desc_logis_imob")}
              onChange={this.changeDesconto}
            />
            <PolicyGroup
              title={
                <span
                  title="Desconto Extra"
                  onClick={event => {
                    event.preventDefault();
                    if (!!policy.hasDescExtra) this.openDescExtraModal();
                  }}
                  className="policy-bar--item__link">
                  Desc. Extra
                  {!!policy.hasDescExtra && <Icon type="dollar" style={{ marginLeft: 2, fontSize: 11 }} />}
                </span>
              }
              descAuto={policy.field("desc_extra_auto", "Aut.")}
              descImob={policy.field("desc_extra_imob", "Imob.")}
              parseDesc={cv}
              onChange={this.changeDesconto}
            />
            {policy.hasDescCliRetira && (
              <PolicyGroup
                title="Desc.Cli.Retira"
                descAuto={policy.field("desc_retir_auto")}
                descImob={policy.field("desc_retir_imob")}
                onChange={this.changeDesconto}
              />
            )}
           {policy.hasDescGeren && (
              <PolicyGroup
                title={
                  <span
                    title="Conta corrente"
                    onClick={event => {
                      event.preventDefault();
                      if (!!policy.hasDescGeren) this.openDescGerenModal();
                    }}
                    className="policy-bar--item__link">
                    Conta Corrente
                    {!!policy.hasDescGeren && <Icon type="info-circle-o" style={{ marginLeft: 5, fontSize: 11 }} />}
                  </span>
                }
                style={{ minWidth: 110 }}
                descAuto={{ ...policy.field("desc_geren_auto"), value: null}}
                descImob={{ ...policy.field("desc_geren_imob"), value: null}}
              />
            )}
            {policy.hasDescFinanceiro && (
              <PolicyGroup
                title="Desc. Financeiro"
                descAuto={policy.field("desc_finan_auto")}
                descImob={policy.field("desc_finan_imob")}
                onChange={this.changeDesconto}
              />
            )}
            {(condicaoTaxa + condicaoTaxa > 0) && (
              <PolicyGroup
                title="Cond. Pagto"
                descAuto={condicaoTaxa}
                descImob={condicaoTaxa}
                parseDesc={(v, t) => {
                  if (t === "auto") {
                    return +v > 0 ? `${percentParseDesc(Math.abs(v))} decrésc` : `0% decrésc`;
                  }

                  return +v < 0 ? `${percentParseDesc(Math.abs(v))} acrésc` : `0% acrésc`;
                }}
                style={{ flex: "0 92px", minWidth: 72 }}
              />
            )}
            
            { policy.hasDescMix && (
            <PolicyGroup
              title="Desc. Mix."
              descAuto={policy.field("desc_mix_auto")}
              descImob={policy.field("desc_mix_imob")}
              onChange={this.changeDesconto}
            />
            )}
            {(policy.data.meta_desc_mix_auto + policy.data.meta_desc_mix_imob > 0) && (
              <PolicyGroup
                title="Meta Desc.Mix"
                descAuto={policy.data.meta_desc_mix_auto}
                descImob={policy.data.meta_desc_mix_imob}
                style={{ flex: "0 92px", minWidth: 72 }}
              />
            )}
          </PolicyBarWrapper>
        </PolicyBarContainer>
        <Modal
          centered
          title="Detalhamento desconto logístico escalonado"
          visible={this.state.isVisiblePolicyModal}
          footer={null}
          onCancel={this.closePolicyModal}
          width={490}
          destroyOnClose={true}
          wrapClassName="policy-modal">
          {policy.hasDescLogisAuto && (
            <TableDescEsc title="Linha Automotiva" desconto={policy.data.desc_logis_regra_escal_auto} />
          )}
          {policy.hasDescLogisImob && (
            <TableDescEsc title="Linha Imobiliária" desconto={policy.data.desc_logis_regra_escal_imob} />
          )}
        </Modal>
        <Modal
          centered
          title="Detalhamento desconto extra"
          visible={this.state.isVisibleDescExtraModal}
          footer={null}
          onCancel={this.closeDescExtraModal}
          width={490}
          destroyOnClose={true}
          wrapClassName="desc-extra-modal">
          {policy.hasDescExtra && <TableDescExtra extras={policy.desconto_extra} />}
        </Modal>
        
        <Modal
          centered
          title="Detalhamento conta corrente"
          visible={this.state.isVisibleDescGerenModal}
          footer={null}
          onCancel={this.closeDescGerenModal}
          width={900}
          height={400}
          destroyOnClose={true}
          wrapClassName="desc-geren-modal">
          {policy.hasDescGeren && <TableDescGeren descs={policy.desconto_geren} />}
        </Modal>

      </Fragment>
    );
  }
}
